<template>
  <a :href="`/admin/advanced-filters/report?name=${report.name}&columns=${report?.selectedColumns.join(',')}`" @click="emit('close')" target="_blank">
    <VTooltip title="Generate Report">
      <icon name="mdi:microsoft-excel" class="h-6 w-6"/>
    </VTooltip>
  </a>
</template>
<script setup lang="ts">
import {exportCsv} from "~/utils/helpers";

const props = defineProps<{
  report: any,
  rolesData: any
  data: any
}>()

const emit = defineEmits(['close']);

const startExport = () => {
  exportCsv(props.report?.selectedColumns, props.data.data?.result, props.rolesData.data)
}
</script>
