<template>
  <div>
    <TransitionRoot as="template" :show="open">
      <Dialog as="div" class="relative z-10" @close="emit('close', false)">
        <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="ease-in duration-200"
            leave-from="opacity-100"
            leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
        </TransitionChild>

        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild
                as="template"
                enter="ease-out duration-300"
                enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enter-to="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leave-from="opacity-100 translate-y-0 sm:scale-100"
                leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel
                  class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl"
              >
                <div class="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                      type="button"
                      class="rounded-md bg-white text-gray-400 hover:text-gray-500"
                      @click="emit('close', false)"
                  >
                    <icon name="heroicons:x-mark-solid" class="h-6 w-6" aria-hidden="true"/>
                  </button>
                </div>
                <div class="px-4 pt-5 pb-4 sm:p-6">
                  <div>
                    <DialogTitle as="h3" class="font-trajan text-2xl text-red-600 mt-2 mb-2">
                      Reports
                    </DialogTitle>
                    <div>
                      <div class="mb-4">
                        <div>
                          <div class="mt-5">
                            <FormKit
                                type="checkbox"
                                :options="options"
                                options-class="$reset grid grid-cols-2 gap-x-4"
                                v-model="selectedOptions"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="border-t border-gray-500 text-center py-6">
                  <button
                      class="font-proxima text-black text-sm font-semibold px-6 py-2 rounded-md capitalize border border-gray-600 mr-4"
                      @click="emit('close', false)"
                  >
                    Cancel
                  </button>
                  <AdminReportsSaveBtn :report="report" @reportSaved="reportSaved"/>
                  <a :href="`/admin/advanced-filters/report?columns=${selectedOptions.join(',')}`" target="_blank"
                     class="font-proxima text-white text-sm font-semibold px-6 py-2 rounded-md capitalize bg-red-500 border border-gray-600"
                  >
                    Generate Report
                  </a>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>
<script setup lang="ts">
import {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot} from '@headlessui/vue';
import {useQuery} from "@tanstack/vue-query";
import {reportOptions} from "~/utils/helpers";
import {useServiceAreaStore} from "~/composables/stores/ServiceAreaStore";
import {useAdvanceReportStore} from "~/composables/stores/AdvanceReportStore";

interface Props {
  open: boolean;
  report?: any;
}

const props = defineProps<Props>();

const emit = defineEmits(['close']);
const reportSaved = () => {
  emit('close', false)
}

const options = ref(reportOptions);

const store = useAdvanceReportStore()
const {formOptions} = storeToRefs(store)

const selectedOptions = ref<string[]>(props.report?.selectedColumns ?? []);
watch(selectedOptions, (value) => {
  formOptions.value = value
});

</script>
