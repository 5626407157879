<template>
  <Disclosure
    v-if="userStoreWithRefs.currentUser"
    v-slot="{ open }"
    as="nav"
    class="border border-gray-200"
    aria-label="Global"
  >
    <div class="mx-auto px-2 sm:px-4 lg:px-8">
      <div class="flex h-16 justify-between">
        <div class="flex items-center px-2 lg:px-0">
          <img class="w-[140px]" src="~/assets/images/sa-logo.png" alt="SAHelp logo" />
        </div>
        <div class="hidden lg:ml-8 lg:flex lg:space-x-4">
          <template v-for="item in navigation" :key="item.name">
            <NuxtLink
              v-if="item.href === '/reports/fund-transactions'"
              :to="item.href"
              @click="fundTransactionStore.setShownSelectFiltersModal(false)"
              class="py-5 px-3 font-proxima text-black text-sm font-semibold hover:text-red-500 hover:border-b-4 hover:border-red-500"
              :class="[
                router.currentRoute.value.path == item.href ||
                (router.currentRoute.value.path.includes(item.href) && item.href != '/' && item.href != '')
                  ? 'text-red-500 border-b-4 border-red-500'
                  : '',
              ]"
              >{{ item.name }}
            </NuxtLink>
            <NuxtLink
              v-else-if="!item.child"
              :to="item.href"
              class="py-5 px-3 font-proxima text-black text-sm font-semibold hover:text-red-500 hover:border-b-4 hover:border-red-500"
              :class="[
                router.currentRoute.value.path == item.href ||
                (router.currentRoute.value.path.includes(item.href) && item.href != '/' && item.href != '')
                  ? 'text-red-500 border-b-4 border-red-500'
                  : '',
              ]"
              >{{ item.name }}
            </NuxtLink>
            <Menu v-else as="div" class="relative inline-block text-left">
              <div>
                <MenuButton as="template" v-slot="{ open }">
                  <button
                    class="py-5 px-3 font-proxima text-black text-sm font-semibold hover:text-red-500 hover:border-b-4 hover:border-red-500"
                    :class="{ 'text-red-500 border-b-4 border-red-500': open }"
                  >
                    {{ item.name }}
                  </button>
                </MenuButton>
              </div>

              <transition
                enter-active-class="transition duration-100 ease-out"
                enter-from-class="transform scale-95 opacity-0"
                enter-to-class="transform scale-100 opacity-100"
                leave-active-class="transition duration-75 ease-in"
                leave-from-class="transform scale-100 opacity-100"
                leave-to-class="transform scale-95 opacity-0"
              >
                <MenuItems
                  class="z-10 absolute right-0 mt-1 w-64 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
                >
                  <div class="px-1 py-1">
                    <MenuItem v-for="child in item.child" :key="child.name" v-slot="{ active }" as="div">
                      <div class="py-2">
                        <AdminReportsBtn v-if="child.name === 'Reports'" />
                        <NuxtLink
                            v-else
                          :href="child.href"
                          class="py-5 px-3 font-proxima text-black text-sm font-semibold hover:text-red-500"
                          >{{ child.name }}
                        </NuxtLink>
                      </div>
                    </MenuItem>
                  </div>
                </MenuItems>
              </transition>
            </Menu>
          </template>
          <button
            v-if="userOnlyHasPmtProcPerms"
            class="py-5 px-3 font-proxima text-black text-sm font-semibold hover:text-red-500 hover:border-b-4 hover:border-red-500"
            @click="showWarning"
          >
            Appointments
          </button>
        </div>
        <div class="flex items-center lg:hidden">
          <DisclosureButton
            class="inline-flex items-center justify-center rounded-md p-2 text-sky-200 hover:bg-sky-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
          >
            <Icon v-if="!open" name="heroicons:bars-3-solid" class="block h-6 w-6 text-black font-bold" />
            <Icon v-else name="heroicons:x-mark-solid" class="block h-6 w-6 text-black font-bold" />
          </DisclosureButton>
        </div>
      </div>
    </div>
    <DisclosurePanel class="lg:hidden">
      <div class="space-y-1 px-2 pt-2 pb-3">
        <DisclosureButton
          v-for="item in navigation"
          :key="item.name"
          as="a"
          :href="item.href"
          class="block rounded-md py-2 px-3 text-base font-medium text-black hover:text-red-500"
          :class="[router.currentRoute.value.path.includes(item.href) ? 'text-red-500 border-b-4 border-red-500' : '']"
          >{{ item.name }}
        </DisclosureButton>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>
<script setup lang="ts">
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { useIntegrationStore } from '~/composables/stores/IntegrationStore';
import { useQuery } from '@tanstack/vue-query';
import { integration_faqs } from '~/composables/consts';

const userStore = useUserStore();
const userStoreWithRefs = storeToRefs(userStore);

const router = useRouter();
const p = ref(userStore.getUserPermissionTypes() ?? []);
const userOnlyHasPmtProcPerms = computed(() => {
  return (
    p.value.filter((value) => value !== 3).length === 0 &&
    !userStoreWithRefs.isAdminForLocation.value &&
    !userStoreWithRefs.hasOversight.value
  );
});

const integrationStore = useIntegrationStore();
const { data } = useQuery(['integrations', 'get'], () =>
  IntegrationModel.checkIntegrationEnabled()
);

const navigation = computed(() => {
  let routes: { name: string; href: string; child?: { name: string; href: string }[] }[] = [
    { name: 'Dashboard', href: '/' },
  ];
  if (
    (userStoreWithRefs.currentUser.value?.profile?.licensedLocationId &&
      userStoreWithRefs.isAdminForLocation.value === true) ||
    userStore.hasAnySAhelpPerms()
  ) {
    routes.push({ name: 'Applicants', href: getOldLink('applicants', '/applicants') });
    routes.push({ name: 'Assistance Requests', href: getOldLink('applications', '/applications') });

    routes.push({ name: 'Service Areas', href: getOldLink('service-areas', '/service-areas') });
    routes.push({ name: 'Funding Sources', href: getOldLink('funding-sources', '/funding-sources') });
    routes.push({ name: 'Funds', href: getOldLink('funds', '/funds') });
    if (!userOnlyHasPmtProcPerms.value) {
      routes.push({ name: 'Appointments', href: getOldLink('appointments', '/appointments') });
    }
    if (data.value?.data) {
      routes.push({ name: 'FAQs', href: '/faqs' });
    }
  }

  if (userStoreWithRefs.isAdminForLocation.value) {
    routes.push({
      name: 'Admin',
      href: '',
      child: [
        // { name: 'Types of Assistance', href: '/admin/additional-assistance-types' },
        { name: 'Requisite Documents', href: '/admin/documents/required' },
        { name: 'Approval Notifications', href: '/admin/documents/notification' },
        { name: 'Funding Source Warnings', href: '/admin/documents/funding-source' },
        { name: 'Food Assistance', href: '/admin/food-assistance' },
        { name: 'Distribution Appointments', href: '/admin/food-assistance/food-appointments' },
        { name: 'Financial Assistance', href: '/admin/financial-assistance' },
        /*{ name: 'Reports', href: '/admin/advanced-filters' },*/
      ],
    });
  }

  if (userStoreWithRefs.isAdminForLocation.value || userStore.isCaseManagerDirector()) {
    routes.push({
      name: 'Transactions',
      href: '/reports/fund-transactions',
    });
  }

  if (userStoreWithRefs.isSysAdmin.value) {
    routes.push({ name: 'Territory Config', href: '/dynamic-form/territory' });
  }

  if (userStoreWithRefs.hasOversight.value) {
    // update routes to only Organizational Stats dashboard
    routes = [{ name: 'Organizational Stats', href: '/oversight' }];
  }

  return routes;
});

const showWarning = () => {
  toastWarning('', 'You do not have permission to view this page.');
};
const fundTransactionStore = useFundTransactionStore();

const getOldLink = (queryIndex: string, query: string) => {
  const oldLink = localStorage.getItem('last-link-'+queryIndex);
  return oldLink ?? query;
};
</script>
